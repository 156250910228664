import axios from 'axios';

export const xBaseUrl = "https://dashboard.climatecircle.com/backend";
export const xToken = 'x_token';
export const xUser = 'x_user';
export const xType = 'x_type';
export const xRefreshTimer = 'x_refreshTimer';
export const xNotification = 'x_notification';
export const xAuthenticated = 'x_authenticated';
export const xUserTypeAdmin = 'admin';
export const xUserTypeUser = 'user';
export const xUserMobile = 'mobile';
export const xUserName = 'x_name';
export const xCustomerRowCount = 'x_customer_row_count';
export const xTaskRowCount = 'x_task_row_count';

const axiosInstance = axios.create({
  baseURL: xBaseUrl,
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(xToken);
  const user = localStorage.getItem(xUser);
  if (token && config.headers) {
    config.headers['x-token'] = token;
    config.headers['x-user'] = user;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 418) {
      // Session Expired
      localStorage.setItem(xAuthenticated, false);
    } else {
      localStorage.setItem(xAuthenticated, true);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 418) {
      // Session Expired
      localStorage.setItem(xAuthenticated, false);
    } else {
      localStorage.setItem(xAuthenticated, true);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
